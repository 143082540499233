import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from "framer-motion";
import AlbumRoundedIcon from '@material-ui/icons/AlbumRounded';

export const LoadingStar = () =>{

    const useStyles = makeStyles({
      styleIcon:{
        position: 'relative',
        fontSize:'2rem',
      },
      loadingSpan:{
        display: 'block'
      },
      loadingContainer:{
        width: "4rem",
        height: "10rem",
        display: "flex",
        justifyContent: "space-around",
        marginLeft:"auto",
        marginRight:"auto",
      },
      divStyle:{
        margin:'auto',
      }
    });
    
    const loadingContainerVariants = {
      start: {
        transition: {
          staggerChildren: 0.2
        }
      },
      end: {
        transition: {
          staggerChildren: 0.2
        }
      }
    };
    
    const loadingCircleVariants = {
      start: {
        y: "10%"
      },
      end: {
        y: "25%"
      }
    };
    
    const loadingCircleTransition = {
      duration: 0.5,
      yoyo: Infinity,
      ease: "easeInOut"
    };


    const classes = useStyles();
    return (
      <div  className={classes.divStyle}> 
    
     <motion.div
      className={classes.loadingContainer} 
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      <motion.span className={classes.loadingSpan} variants={loadingCircleVariants} transition={loadingCircleTransition}>
      <AlbumRoundedIcon className={classes.styleIcon} />
      </motion.span>

      <motion.span className={classes.loadingSpan} variants={loadingCircleVariants} transition={loadingCircleTransition}>
      <AlbumRoundedIcon className={classes.styleIcon} />
      </motion.span>

      <motion.span className={classes.loadingSpan} variants={loadingCircleVariants} transition={loadingCircleTransition}>
      <AlbumRoundedIcon className={classes.styleIcon} />
      </motion.span>

    </motion.div>
      </div>
    );
}