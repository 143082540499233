export const handleError = (error) => {

    var message = "Przepraszamy coś poszło nie tak.";
    var errorResponseData = error?.response?.data;

    if(errorResponseData)
    {
        if(errorResponseData.detail)
        {
            message = errorResponseData.detail;
        }
        else if(errorResponseData.errors)
        {
            var objKey = Object.keys(errorResponseData.errors);

            if(objKey.length === 1)
            {
                let arrayOfErrors = errorResponseData.errors[objKey[0]];
                message = "";
                arrayOfErrors.map(x => message += `${x} `)
            }
        }
    }
    return message;
}
