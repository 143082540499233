import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router";

interface WrapperProps {
  initialized: boolean;
  children: React.PropsWithChildren<any>;
}

export function GAWrapper(props: WrapperProps) {
  const location = useLocation();

  useEffect(() => {
    if (props.initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [props.initialized, location]);

  return props.children;
}
