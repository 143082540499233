import React from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { APIUrl } from "../Enums";
import { getURL } from "../helpers/url-helper";
import { handleError } from "../helpers/error-helper";
import TextField from "@material-ui/core/TextField";
import { SongBase } from "../models/Song";
import { defineImage } from "../helpers/image-helper";
import InputAdornment from "@material-ui/core/InputAdornment";
import debounce from "lodash.debounce";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { LoadingStar } from "../components/loading-star";
import Divider from "@material-ui/core/Divider";
import {eventTrack} from '../helpers/useAnaliticsHook'
import { Link } from "react-router-dom";
import { Paths } from "../helpers/constants";

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

const useStyles = makeStyles({
  searchInput: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
        borderWidth: "1px"
      }
    }
  },
  listItem: {
    cursor: "pointer",
    maxWidth: 240,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)"
    }
  }
});

export const SearchDrawer = (props: Props) => {
  const classes = useStyles();

  const [options, setOptions] = React.useState<SongBase[]>([]);
  const [loading, setLoading] = React.useState(false);

  const handleOnChange = debounce(value => {
    if (value.length > 2) {
      setLoading(true);
      eventTrack("Navbar", "Searching", `SearchValue: ${value}`)
      axios.get(`${getURL(APIUrl.SearchStory)}${value}`).then(
        res => {
          setOptions(res.data);
          props.setOpen(true);
          setLoading(false);
        },
        error => {
          let message = handleError(error);
          console.error(message);
          setLoading(false);
        }
      );
    }
  }, 1000);

  const handleOnOptionClick = () => {
    props.setOpen(false);
  };

  const drawerWidth = 240;
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      anchor="right"
      open={props.isOpen}
      onClose={() => props.setOpen(false)}
      onOpen={() => props.setOpen(true)}
      style={{ maxWidth: drawerWidth }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <TextField
        className={classes.searchInput}
        name="search"
        placeholder="Wyszukaj..."
        variant="outlined"
        type="search"
        size="small"
        autoComplete="off"
        onChange={e => handleOnChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" color="inherit">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      {loading ? (
        <div style={{ marginTop: 5 }}>
          <LoadingStar />
        </div>
      ) : (
        <List style={{ maxWidth: drawerWidth }}>
          {options && options.length > 0 ? (
            options.map(story => (
              <React.Fragment key={`drawer-option-${story.bandName}-${story.id}`}>
                <Link  to={Paths.stories.viewStory(story.bandLink, story.songLink)} style = {{display: 'flex', textDecoration: "none", color:"inherit"}}>
                <ListItem
                  className={classes.listItem}
                  onClick={() => handleOnOptionClick()}
                  
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Avatar
                        alt={story.bandName}
                        src={defineImage(story.imageUrl)}
                        style={{
                          border: "0.1px solid rgba(0, 0, 0, 0.12)"
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={story.songName}
                    secondary={story.bandName}
                  />
                </ListItem>
                </Link>
                <Divider variant="fullWidth" component="li" />
              </React.Fragment>
            ))
          ) : (
            <React.Fragment>
              {!loading && (
                <React.Fragment>
                  <ListItem>
                    <ListItemText secondary="Wpisz minimum 3 znaki i wyszukuj historię po nazwie piosenki, zespołu, albumu, artyście czy roku powstania." />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </List>
      )}
    </SwipeableDrawer>
  );
};
