import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AlbumRoundedIcon from '@material-ui/icons/AlbumRounded';
import MusicNoteRoundedIcon from '@material-ui/icons/MusicNoteRounded';
import CasinoRoundedIcon from '@material-ui/icons/CasinoRounded';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import AppBar from '@material-ui/core/AppBar';
import axios from 'axios';
import { APIUrl, Url } from '../Enums';
import { getURL } from '../helpers/url-helper';
import { handleError } from '../helpers/error-helper';
import { useLocation } from 'react-router-dom';
import { Paths } from "../helpers/constants";

export const BottomNav = memo(() => {
  const history = useHistory();
  const [value, setValue] = useState(0);

  const location = useLocation();
  useEffect(() => {
    checkActualRout();
  }, [location]);

  const handleOnClickBands = useCallback(() => history.push(`/${Url.Bands}/A-B`), [history]);
  const handleOnClickSongs = useCallback(() => history.push(`/${Url.Songs}/A-B`), [history]);
  const handleOnClickBack = useCallback(() => history.goBack(), [history]);

  const checkActualRout = useCallback(() => {
    var actualPathName = window.location.pathname;

    if (actualPathName) {
      if (actualPathName.includes(Url.Songs)) {
        setValue(2);
      }
      else if (actualPathName.includes(Url.Bands)) {
        setValue(3);
      }
      else {
        setValue(4);
      }
    }
  }, [])

  function searchRandomStory() {
    axios.get(`${getURL(APIUrl.RandomStory)}`)
      .then(res => {
        const randomStory = res.data;
        if (randomStory.hasOwnProperty("bandLink") && randomStory.hasOwnProperty("songLink")) {
          history.push(Paths.stories.viewStory(randomStory.bandLink, randomStory.songLink));
        }
        else {
          console.error("Nie udało się pobrać losowej historii.")
        }
      }, (error) => {
        let message = handleError(error);
        console.error(message);
      })
  }

  return (
    <AppBar position="fixed" color="primary" style={{ top: 'auto', bottom: 0, }} data-nosnippet>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction label="Wstecz" icon={<KeyboardBackspaceRoundedIcon />} onClick={handleOnClickBack} />
        <BottomNavigationAction label="Losuj" icon={<CasinoRoundedIcon />} onClick={searchRandomStory} />
        <BottomNavigationAction label="Piosenki" icon={<MusicNoteRoundedIcon />} onClick={handleOnClickSongs} />
        <BottomNavigationAction label="Zespoły" icon={<AlbumRoundedIcon />} onClick={handleOnClickBands} />
      </BottomNavigation>
    </AppBar>
  );
})