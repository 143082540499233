import { useState, useEffect } from "react";
import ReactGA from "react-ga4";

export function useAnalytics(){
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        ReactGA.initialize('G-JCXTFGPZLG');
        setInitialized(true)
    },[])

    return {initialized}
}

export function eventTrack(
    category:"Navbar", 
    action:string, 
    label?:string){
    ReactGA.event({
        category: category,
        action: action,
        label:label
    })
}