import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { NavBar } from "./views/nav-bar";
import ScrollToTop from './components/scroll-to-top';
import { BottomNav } from './components/bottom-nav';
import { Url } from './Enums';
import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useAnalytics } from './helpers/useAnaliticsHook'
import { GAWrapper } from './helpers/GAWrapper'
import { MobileView } from 'react-device-detect';
import { HelmetProvider } from 'react-helmet-async';
import { LoadingStar } from './components/loading-star';

const HomePage = lazy(() => import("./views/home").then((module) => ({ default: module.Home })));
const StoryPage = lazy(() => import("./views/story").then((module) => ({ default: module.Story })));
const StoriesPage = lazy(() => import("./views/stories").then((module) => ({ default: module.Stories })));
const BandPage = lazy(() => import("./views/band").then((module) => ({ default: module.Band })));
const BandsPage = lazy(() => import("./views/bands").then((module) => ({ default: module.Bands })));
const StoriesSearchPage = lazy(() => import("./views/stories-searching").then((module) => ({ default: module.StoriesSearching })));
const MapPage = lazy(() => import("./views/mapView").then((module) => ({ default: module.MapView })));
const MapCityPage = lazy(() => import("./views/mapViewCity").then((module) => ({ default: module.MapViewCity })));
const PolicyPage = lazy(() => import("./views/policy").then((module) => ({ default: module.Policy })));
const NotFoundPage = lazy(() => import("./views/not-found").then((module) => ({ default: module.NotFound })));
const AddStoryPage = lazy(() => import("./views/add-story").then((module) => ({ default: module.AddStory })));

const CookieNoticeBanner = lazy(() => import("./components/cookie-notice-banner").then((module) => ({ default: module.CookieNoticeBanner })));

const generateClassName = createGenerateClassName({
  seed: 'myclasses-'
});

const App = () => {

  const [key, setKey] = React.useState(0);

  React.useEffect(() => {
    setKey(1);
  }, []);

  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const { initialized } = useAnalytics();
  var routes = (
    <div style={{ paddingTop: "20px" }}>
      <Switch>
        <GAWrapper initialized={initialized}>
          <Route path={["/", "/RockGaweda/"]} exact component={HomePage} />
          <Route path={`/${Url.Story}/:bandLink/:songLink`} exact component={StoryPage} />
          <Route path={`/${Url.Stories}/:storiesType`} exact component={StoriesPage} />
          <Route path={`/${Url.Songs}/:searchValue`} exact component={StoriesSearchPage} />
          <Route path={`/${Url.Bands}/:searchValue`} exact component={BandsPage} />
          <Route path={`/${Url.Band}/:bandLink`} exact component={BandPage} />
          <Route path={`/${Url.AddStory}`} exact component={AddStoryPage} />
          <Route path={`/${Url.Map}`} exact component={MapPage} />
          <Route path={`/${Url.Map}/:cityLink`} exact component={MapCityPage} />
          <Route path={`/${Url.Policy}`} exact component={PolicyPage} />
        </GAWrapper>
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  );

  return (
    <React.Fragment>
      <StylesProvider key={key} generateClassName={generateClassName}>
        <React.Fragment>
          {/* <ThemeProvider > */}
          <CssBaseline />
          <Router>
            <NavBar children={routes} />
            <ScrollToTop />
            <Suspense fallback={<LoadingStar />}>
              <HelmetProvider>
                {routes}
                <MobileView>
                  <BottomNav />
                </MobileView>
              </HelmetProvider>
              <CookieNoticeBanner />
            </Suspense>
          </Router>
          {/* </ThemeProvider> */}
        </React.Fragment>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
