import { Point } from "pigeon-maps";
import { StoriesTypes, Url } from "../Enums";

export const Paths = {
    stories:{
        viewStory: (bandLink:string, songLink:string) => `/${Url.Story}/${bandLink}/${songLink}`,
        searchStories: (searchValue:string) => `/${Url.Songs}/${searchValue}`,
        top: `/${Url.Stories}/${StoriesTypes.Top}`,
        fresh:`/${Url.Stories}/${StoriesTypes.Fresh}`,
        add: "/dodaj"
    },
    bands:{
        viewBand: (bandLink:string) => `/${Url.Band}/${bandLink}`,
        searchBands: (searchValue:string) => `/${Url.Bands}/${searchValue}`
    },
    map: {
        map: `/${Url.Map}`,
        viewCity: (cityLink: string) => `/${Url.Map}/${cityLink}`,
    },
    home: "/"
}

const homeURL = "https://rockgaweda.pl/";

export const CanonicalLink = {
    home: homeURL,
    add: `${homeURL}dodaj`,
    top: `${homeURL}${Url.Stories}/${StoriesTypes.Top}`,
    fresh: `${homeURL}${Url.Stories}/${StoriesTypes.Fresh}`,
    searchBand:`${homeURL}${Url.Bands}/A-B`,
    searchSong:`${homeURL}${Url.Songs}/A-B`,
    policy:`${homeURL}polityka-prywatnosci`,
    story: (bandLink:string, songLink:string) => `${homeURL}${Url.Story}/${bandLink}/${songLink}`,
    band: (bandLink:string) => `${homeURL}${Url.Band}/${bandLink}`,
    map: `${homeURL}${Url.Map}`,
    mapCity: (cityLink:string) => `${homeURL}${Url.Map}/${cityLink}`
}

export const MapConfiguration = {
    zoom:{
        generalMap: {
            mobile: 5.6,
            desktop: 5.75
        },
        locationMap: 11
    },
    height: 400,
    defineCenterPoint: (latitude: number, longitude: number) => [latitude, longitude] as Point,
    polishCenterPoint: [51.9194, 19.1451] as Point,
    mapPopperTimeOut: 350
}