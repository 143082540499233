export enum TopStoriesCategory{
    Views,
    News
}

export enum AlertTypes{
    Error = 'error',
    Warning = 'warning',
    Success = 'success',
    Info = 'info'
}

export enum APIUrl{
    TopStories = 'api/stories/top',
    TopListStories = 'api/stories/top/list',
    FreshStories = 'api/stories/fresh',
    FreshListStories = 'api/stories/fresh/list',
    RandomStories = 'api/stories/random/',
    RandomStory = 'api/stories/random-story',
    Story = 'api/stories/story/',
    Band = 'api/bands/link/',
    Bands = 'api/bands/starts-with/',
    BandsBySearchValue= 'api/bands/search/',
    Stories = 'api/stories/starts-with/',
    AddBand = 'api/bands/',
    AddStory = 'api/stories/',
    SearchStory = 'api/stories/search/',
    AllCities = 'api/cities/get-all',
    City = 'api/cities/link/',
}

export enum StoriesTypes{
    Top = 'top',
    Fresh = 'najnowsze',
    Search = 'wyszukaj',
}

export enum MarkDownPrefix{
    Intro='',
    MusicPart= '## Muzyka \n',
    TextPart= '## Tekst \n',
    CuriositiesPart= '## Ciekawostki \n',
    SourcesPart= '##### Źródła \n',
}

export enum AritcleListedTypes{
    Curiosities = 1,
    Sources,
    Other,
}

export enum PresentationCardTypes{
    Song = 1,
    Band,
}

export enum TitlesBase{
    Intro = "Wstęp",
    MusicPart = 'Muzyka',
    TextPart= 'Tekst',
    CuriositiesPart= 'Ciekawostki',
    SourcesPart= 'Źródła',
    MapPart='Powiązane lokalizacje'
}

export enum Url{
    Story = "historia",
    Stories = "historie",
    Songs = "piosenki",
    Bands = "zespoly",
    Band = "zespol",
    AddStory = "dodaj",
    Policy = "polityka-prywatnosci",
    Map = "mapa"
}