import {APIUrl} from '../Enums';

export const getURL = (operation:APIUrl) => {
    //const urlBase = 'https://localhost:5001/';
    const urlBase = 'https://rockgaweda-api.herokuapp.com/';
    switch (operation) {
        case APIUrl.TopStories:
            return `${urlBase + APIUrl.TopStories}`
        case APIUrl.FreshStories:
            return `${urlBase + APIUrl.FreshStories}`
        case APIUrl.TopListStories:
            return `${urlBase + APIUrl.TopListStories}`
        case APIUrl.FreshListStories:
            return `${urlBase + APIUrl.FreshListStories}`
        case APIUrl.Story:
            return `${urlBase + APIUrl.Story}`
        case APIUrl.Band:
            return `${urlBase + APIUrl.Band}`
        case APIUrl.Bands:
            return `${urlBase + APIUrl.Bands}`
        case APIUrl.RandomStories:
                return `${urlBase + APIUrl.RandomStories}`
        case APIUrl.RandomStory:
            return `${urlBase + APIUrl.RandomStory}`
        case APIUrl.Stories:
            return `${urlBase + APIUrl.Stories}`
        case APIUrl.BandsBySearchValue:
            return `${urlBase + APIUrl.BandsBySearchValue}`
        case APIUrl.AddBand:
            return `${urlBase + APIUrl.AddBand}` 
        case APIUrl.AddStory:
            return `${urlBase + APIUrl.AddStory}` 
        case APIUrl.SearchStory:
            return `${urlBase + APIUrl.SearchStory}`
        case APIUrl.AllCities:
            return `${urlBase + APIUrl.AllCities}`
        case APIUrl.City:
                return `${urlBase + APIUrl.City}`
        default:
            throw new Error(`${operation} is not defined in switch.`)
        }
}
