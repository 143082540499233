import VinylWebp from '../images/vinyl500px.webp';

export const defineImage = (url?:string) => {
        if(url){
                const urlParams = new URLSearchParams(new URL(url).search);
                const idValue = urlParams.get("id");
                return `https://drive.google.com/thumbnail?id=${idValue}&sz=w1000`
        }
        return VinylWebp;
}

export const setDefault = () => {
        return VinylWebp;
}
