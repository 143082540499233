import React, { memo } from "react";
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import StarHalf from "@material-ui/icons/StarHalf";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import CasinoRoundedIcon from "@material-ui/icons/CasinoRounded";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import CssBaseline from "@material-ui/core/CssBaseline";
import { APIUrl } from "../Enums";
import { getURL } from "../helpers/url-helper";
import { handleError } from "../helpers/error-helper";
import { SearchDrawer } from '../components/search-drawer'
import { eventTrack } from '../helpers/useAnaliticsHook'
import { BrowserView } from 'react-device-detect';
import { Paths } from "../helpers/constants";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const useStyles = makeStyles({
  title: {
    fontWeight: 800,
    fontFamily: "Sansita Swashed",
    flexGrow: 1,
    display: "block"
  },
  starIcon: {
    position: "relative",
    top: "5px"
  },
  linkStyle: {
    textDecoration: "none",
    color: "inherit"
  }
});

export const NavBar = memo((props: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  function ElevationScroll(props: Props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0
    });
  }

  const onSearchIconClick = () => {
    eventTrack("Navbar", "Clicked search icon")
    setOpen(true)
  }

  function searchRandomStory() {
    eventTrack("Navbar", "Clicked get random story")
    axios.get(`${getURL(APIUrl.RandomStory)}`).then(
      res => {
        const randomStory = res.data;
        if (
          randomStory.hasOwnProperty("bandLink") &&
          randomStory.hasOwnProperty("songLink")
        ) {
          history.push(Paths.stories.viewStory(randomStory.bandLink, randomStory.songLink))
        } else {
          console.error("Nie udało się pobrać losowej historii.");
        }
      },
      error => {
        let message = handleError(error);
        console.error(message);
      }
    );
  }

  const logoDesktop = (
    <Typography className={classes.title} variant="h6" noWrap>
      <Link to="/" className={classes.linkStyle}>
        R<StarHalf className={classes.starIcon} />
        ck Gawęda
      </Link>
    </Typography>
  );

  return (
    <div data-nosnippet>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar id="app-bar" position="fixed" className="not-scrolled">
          <Toolbar>
            {logoDesktop}
            <Tooltip title="Rockowa mapa Polski" placement="bottom">
            <Link to={Paths.map.map} className={classes.linkStyle}>
              <IconButton
                aria-label="Rockowa mapa Polski"
                aria-controls="menu-appbar"
                color="inherit"
              >
                <MapRoundedIcon />
              </IconButton>
              </Link>
            </Tooltip>
            <BrowserView>
              <Tooltip title="Losuj historię" placement="bottom">
                <IconButton
                  aria-label="Losuj historię"
                  aria-haspopup="true"
                  color="inherit"
                  aria-controls="menu-appbar"
                  onClick={searchRandomStory}
                >
                  <CasinoRoundedIcon />
                </IconButton>
              </Tooltip>
            </BrowserView>
            <Tooltip title="Wyszukaj" placement="bottom">
              <IconButton
                aria-label="Wyszukaj w serwisie"
                aria-haspopup="true"
                color="inherit"
                aria-controls="menu-appbar"
                onClick={onSearchIconClick}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            {open && <SearchDrawer isOpen={open} setOpen={setOpen} /> }
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
});
